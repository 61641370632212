import { useCompanyLogo } from "../../../../hooks";
import "./broker-name-card.scss";
import SilkSvg from "./silk-svg";

function BrokerNameCard({ name, title, phoneNumber, email }) {
  const { darkLogo } = useCompanyLogo();
  return (
    <div className="broker-name-card">
      {darkLogo && (
        <div className="broker-name-card__logo">
          <img alt="Experity-site-logo" src={darkLogo} />
        </div>
      )}
      <div className="broker-name-card__name">{name}</div>
      <div className="broker-name-card__title">{title}</div>
      <div className="broker-name-card__phone-number">{phoneNumber}</div>
      <div className="broker-name-card__email">{email}</div>
      <div className="broker-name-card__silk">
        <SilkSvg />
      </div>
    </div>
  );
}

export default BrokerNameCard;
