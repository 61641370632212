import React from "react";
import { primaryColor } from "../../../../scss/colors.scss";

const SilkSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161.546"
      height="149.433"
      viewBox="0 0 161.546 149.433"
    >
      <g id="Group_6191" data-name="Group 6191" transform="translate(0 0)">
        <path
          id="Path_2320"
          data-name="Path 2320"
          d="M8864.8,12354.246l-90.154,46.372-71.392-36.321,161.546-79.22Z"
          transform="translate(-8703.251 -12285.077)"
          fill="#223645"
        />
        <path
          id="Path_2321"
          data-name="Path 2321"
          d="M8842.16,12451.558l-139.645-69.721.01,69.721Z"
          transform="translate(-8702.516 -12302.125)"
          fill={primaryColor}
        />
        <path
          id="Path_2322"
          data-name="Path 2322"
          d="M8702.516,12381.837v68.914h3.934l66.809-33.082Z"
          transform="translate(-8702.516 -12302.945)"
          fill="#223645"
          opacity="0.6"
        />
      </g>
    </svg>
  );
};

export default SilkSvg;
